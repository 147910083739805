import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import adminReducer, { AdminState } from './slices/adminSlice';
import commonReducer, { CommonState } from './slices/commonSlice';
import deviceSlice, { DeviceState } from './slices/deviceSlice';
import vbwReducer, { VbwState } from './slices/vbwSlice';
import videoReducer, { VideoState } from './slices/videoSlice';
import functionSlice, { FunctionState } from './slices/functionSlice';

export interface RootState {
  common: CommonState;
  admin: AdminState;
  video: VideoState;
  vbw: VbwState;
  device: DeviceState;
  function: FunctionState;
}

export default configureStore({
  reducer: {
    common: commonReducer,
    admin: adminReducer,
    video: videoReducer,
    vbw: vbwReducer,
    device: deviceSlice,
    function: functionSlice,
  },
  middleware: [...getDefaultMiddleware({ immutableCheck: false, serializableCheck: false })],
  devTools: true,
});
