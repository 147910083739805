import { useSelector } from '../../../reduxToolkit/typedSelector';

export function usePermission() {
  const meeting = useSelector(({ video }) => video?.meeting);
  const user = useSelector(({ common }) => common.user);
  const event = useSelector(({ common }) => common.event);

  function isAdmin() {
    if (!meeting && !event) return false;
    if (!user) return false;
    if (!user.organization) return false;
    if (user.role === 'PARTICIPANT_ANONYM') return false;

    return (
      (meeting?.booth && user.organization._id === meeting?.booth.organization._id) ||
      (meeting?.event && meeting.event.organization && user.organization._id === meeting?.event.organization._id) ||
      (meeting?.booth && meeting.booth.event?.organization && user.organization._id === meeting?.booth.event.organization._id) ||
      event?.organization?._id === user.organization._id
    );
  }

  function isNotRegistered() {
    return user === null || user === undefined;
  }

  return { isAdmin, isNotRegistered };
}
