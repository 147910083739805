var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"e570ae16d00093f1ef0fecc4711e0273d2be1de4"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
Sentry.addBreadcrumb({
  type: 'TypeError',
});
Sentry.init({
  dsn: SENTRY_DSN || 'https://56794750be5048be9c287d71c7242059@o702088.ingest.sentry.io/5777445',
  environment: 'local',
  debug: false,
  autoSessionTracking: true,
  tracesSampleRate: 1,
  /*beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialogxw
    if (event && event?.exception?.values?.find((v) => v.type === 'TypeError')) {
      Sentry.showReportDialog({ eventId: event.event_id });
    }
    return event;
  },*/
  integrations: [],
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
